<template>
  <step-intro-layout :objectives="objectives"
    class="module1-part3-intro"
    @go-to-lesson="goToNextStep"
  >
  </step-intro-layout>
</template>

<script>
import StepIntroLayout from '@/components/training/StepIntroLayout'
import useTrainingUtils from '@/utils/useTrainingUtils'

export default {
  name: 'Module1Part3Intro',
  components: { StepIntroLayout },
  emits: ['next-step', 'step-finished'],
  setup (props, context) {
    const { goToNextStep } = useTrainingUtils()
    return { goToNextStep }
  },
  data () {
    return {
      objectives: [
        this.$t('module1.part3.intro.objectif1')
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.module1-part3-intro {
  background-image: url('../../assets/logos/aides/logo-mpr-opacity.svg');
  background-repeat: no-repeat;
  background-position: right -30px top 50px;
  background-size: 40% auto;
}

@media (min-width: $bp-tablet) {
  .module1-part3-intro {
    background-position: right 5% top 20px;
    background-size: 30% auto;
  }
}
</style>
